









































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { getComponent } from '@/utils/helpers';
import accountModule from '@/store/modules/accountModule';
import operationsModule from '@/store/modules/operationsModule';
import { DateTime } from 'luxon';
import tasqsListModule from '@/store/modules/tasqsListModule';
import { ALL_TASQS_LIST_ITEM } from '@/lib/constants';





@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    DoughnutChart: () => import('@/lib/charts/doughnutChart'),
    StatusPieCharts: () => getComponent('operations/StatusPieCharts'),
  },
})
export default class Home extends Vue {

    firstPieChartData: any[] = []
    secondPieChartData: any[] = []
    thirdPieChartData: any[] = []
    tasqsPerStatusData = []
    tasqsPerRouteData = []
    tasqsPerRoleData = []
    topDefermentCards = []
    topFailureCards = []
    totalPieChartTasqs = 0
    pieChartTitle = ""

    plannedWorkSectionOptions = [
        "All",
        "New wells",
        "Workover handovers",
        "Gauges",
        "PMs",
        "Projects"
    ]



    mapPredictionType = {
        "Anomaly": "Anomaly",
        "state change": "Anomaly",
        "Prolonged Anomaly": "Anomaly",
        "Off-Target": "Off-Target",
        "Workflow": "Workflow",
        "Prediction": "Prediction",
        "No Comms": "No Comms"
    }
    mapRoleType = {
        "FieldOperator": "Operators",
        "Operator": "Operators",
        "Wireline": "Wireline",
        "Engineering": "Engineering",
        "Workover": "Workover",
        "Automation": "Automation",
        "Optimizers": "Optimizers"
    }




    showFirstPieChart = true
    showSecondPieChart = false
    showThirdPieChart = false
    showTalliesPopupShowing = true
    tasqsSummarySectionOption = "tasqs per status"



plannedWorkDataAll = [
        {
        "id": 1,
        "day_of_week": "monday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": [
            ]
        }
    },
    {
        "id": 2,
        "day_of_week": "tuesday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": [
            ]
        }
    },	{
        "id": 3,
        "day_of_week": "wednesday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    },	{
        "id": 4,
        "day_of_week": "thursday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    },	{
        "id": 5,
        "day_of_week": "friday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    }]




plannedWorkDataNewWells = [
        {
        "id": 1,
        "day_of_week": "monday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    },
    {
        "id": 2,
        "day_of_week": "tuesday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    },	{
        "id": 3,
        "day_of_week": "wednesday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    },	{
        "id": 4,
        "day_of_week": "thursday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    },	{
        "id": 5,
        "day_of_week": "friday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    }]




plannedWorkDataWorkoverHandovers = [
        {
        "id": 1,
        "day_of_week": "monday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    },
    {
        "id": 2,
        "day_of_week": "tuesday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    },	{
        "id": 3,
        "day_of_week": "wednesday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    },	{
        "id": 4,
        "day_of_week": "thursday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    },	{
        "id": 5,
        "day_of_week": "friday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    }]




plannedWorkDataGauges = [{
        "id": 1,
        "day_of_week": "monday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    },
    {
        "id": 2,
        "day_of_week": "tuesday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    },	{
        "id": 3,
        "day_of_week": "wednesday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    },	{
        "id": 4,
        "day_of_week": "thursday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    },	{
        "id": 5,
        "day_of_week": "friday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    }]




plannedWorkDataPMs = [
        {
        "id": 1,
        "day_of_week": "monday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    },
    {
        "id": 2,
        "day_of_week": "tuesday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    },	{
        "id": 3,
        "day_of_week": "wednesday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    },	{
        "id": 4,
        "day_of_week": "thursday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    },	{
        "id": 5,
        "day_of_week": "friday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    }]




  plannedWorkDataProjects = [
        {
        "id": 1,
        "day_of_week": "monday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": [
            ]
        }
    },
    {
        "id": 2,
        "day_of_week": "tuesday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": [
            ]
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": [
            ]
        }
    },	{
        "id": 3,
        "day_of_week": "wednesday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": [
            ]
        }
    },	{
        "id": 4,
        "day_of_week": "thursday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": [
            ]
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": [
            ]
        }
    },	{
        "id": 5,
        "day_of_week": "friday",
        "completion_percentage": 0,
        "date_string": "",
        "tasq_count": 0,
        "new_wells": {
            "count": 0,
            "wells": []
        },
        "workover_handovers": {
            "count": 0,
            "wells": [
            ]
        },
        "gauges": {
            "count": 0,
            "wells": [
            ]
        },
        "PMs": {
            "count":0,
            "wells": [
            ]
        },
        "Projects": {
            "count":0,
            "wells": []
        }
    }]









    tasqsPerStatusTapped() {
        this.tasqsSummarySectionOption = "tasqs per status"
    }
    tasqsPerRouteTapped() {
        this.tasqsSummarySectionOption = "tasqs per route"
    }
    tasqsPerRoleTapped() {
        this.tasqsSummarySectionOption = "tasqs per role"
    }

    showOperationsChartsVal = false

    hideOperationsCharts() {
        this.showOperationsChartsVal = false
    }


    populatePieChartData(dataPieChart, x) {
        dataPieChart.count += 1
        var updatedUsername = false
        for (var w = 0; w < dataPieChart.data.length; w++) {
            if (dataPieChart.data[w].username == this.totalTasqsListArrayData[x].Username) {
                dataPieChart.data[w].total_tasqs += 1
                updatedUsername = true
            }
        }
        if (!updatedUsername) {
            dataPieChart.data.push({
                username: this.totalTasqsListArrayData[x].Username,
                total_tasqs: 1
            })
        }
        return dataPieChart
    }


    showOperationsCharts(selection) {
        this.firstPieChartData = []
        if (this.tasqsSummarySectionOption == "tasqs per status") {
                this.pieChartTitle = selection.title
                var incompleteDataPieChartData: any[] = []
                var totalJobCount = 0
                var incompleteDataPieChart = {
                    count: 0,
                    total: 0,
                    name: "Incomplete",
                    color: "#ff5e25",
                    id: 0,
                    data: incompleteDataPieChartData
                }
                var reassignedDataPieChartData: any[] = []
                var reassignedDataPieChart = {
                    count: 0,
                    total: 0,
                    name: "Reassigned",
                    color: "#006cff",
                    id: 1,
                    data: reassignedDataPieChartData
                }
                var snoozedDataPieChartData: any[] = []
                var snoozedDataPieChart = {
                    count: 0,
                    total: 0,
                    name: "Snoozed",
                    color: "#ffd001",
                    id: 2,
                    data: snoozedDataPieChartData
                }
                var completeDataPieChartData: any[] = []
                var completeDataPieChart = {
                    count: 0,
                    total: 0,
                    name: "Complete",
                    color: "#4cdf95",
                    id: 3,
                    data: completeDataPieChartData
                }
                for (var x = 0; x < this.totalTasqsListArrayData.length; x++) {
                    if (this.mapPredictionType[this.totalTasqsListArrayData[x].PredictionType] == selection.title) {
                        totalJobCount += 1
                        if (this.totalTasqsListArrayData[x].completed != null && this.totalTasqsListArrayData[x].completed) {
                            completeDataPieChart = this.populatePieChartData(completeDataPieChart, x)
                        } else {
                            incompleteDataPieChart = this.populatePieChartData(incompleteDataPieChart, x)
                        }
                    }
                }

            incompleteDataPieChart.total = totalJobCount
            completeDataPieChart.total = totalJobCount
            this.totalPieChartTasqs = totalJobCount

			this.firstPieChartData.push(incompleteDataPieChart)
            this.firstPieChartData.push(completeDataPieChart)


        } else if (this.tasqsSummarySectionOption == "tasqs per route") {
                this.pieChartTitle = selection.title
                var incompleteDataPieChartData: any[] = []
                var totalJobCount = 0
                var incompleteDataPieChart = {
                    count: 0,
                    total: 0,
                    name: "Incomplete",
                    color: "#ff5e25",
                    id: 0,
                    data: incompleteDataPieChartData
                }
                var reassignedDataPieChartData: any[] = []
                var reassignedDataPieChart = {
                    count: 0,
                    total: 0,
                    name: "Reassigned",
                    color: "#006cff",
                    id: 1,
                    data: reassignedDataPieChartData
                }
                var snoozedDataPieChartData: any[] = []
                var snoozedDataPieChart = {
                    count: 0,
                    total: 0,
                    name: "Snoozed",
                    color: "#ffd001",
                    id: 2,
                    data: snoozedDataPieChartData
                }
                var completeDataPieChartData: any[] = []
                var completeDataPieChart = {
                    count: 0,
                    total: 0,
                    name: "Complete",
                    color: "#4cdf95",
                    id: 3,
                    data: completeDataPieChartData
                }
                for (var x = 0; x < this.totalTasqsListArrayData.length; x++) {
                    console.log("HERRRRRR")
                    console.log(this.totalTasqsListArrayData[x])
                    if (this.totalTasqsListArrayData[x].Route == selection.title) {
                        totalJobCount += 1
                        if (this.totalTasqsListArrayData[x].completed != null && this.totalTasqsListArrayData[x].completed) {
                            completeDataPieChart = this.populatePieChartData(completeDataPieChart, x)
                        } else {
                            incompleteDataPieChart = this.populatePieChartData(incompleteDataPieChart, x)
                        }
                    }
                }

            incompleteDataPieChart.total = totalJobCount
            completeDataPieChart.total = totalJobCount
            this.totalPieChartTasqs = totalJobCount
            this.firstPieChartData.push(incompleteDataPieChart)
            this.firstPieChartData.push(completeDataPieChart)

        } else if (this.tasqsSummarySectionOption == "tasqs per role") {

                this.pieChartTitle = selection.title
                var incompleteDataPieChartData: any[] = []
                var totalJobCount = 0
                var incompleteDataPieChart = {
                    count: 0,
                    total: 0,
                    name: "Incomplete",
                    color: "#ff5e25",
                    id: 0,
                    data: incompleteDataPieChartData
                }
                var reassignedDataPieChartData: any[] = []
                var reassignedDataPieChart = {
                    count: 0,
                    total: 0,
                    name: "Reassigned",
                    color: "#006cff",
                    id: 1,
                    data: reassignedDataPieChartData
                }
                var snoozedDataPieChartData: any[] = []
                var snoozedDataPieChart = {
                    count: 0,
                    total: 0,
                    name: "Snoozed",
                    color: "#ffd001",
                    id: 2,
                    data: snoozedDataPieChartData
                }
                var completeDataPieChartData: any[] = []
                var completeDataPieChart = {
                    count: 0,
                    total: 0,
                    name: "Complete",
                    color: "#4cdf95",
                    id: 3,
                    data: completeDataPieChartData
                }
                for (var x = 0; x < this.totalTasqsListArrayData.length; x++) {
                    console.log("ROLE: ")
                    console.log(this.totalTasqsListArrayData[x])
                    if (this.mapRoleType[this.totalTasqsListArrayData[x].Role] == selection.title) {
                        totalJobCount += 1
                        if (this.totalTasqsListArrayData[x].completed != null && this.totalTasqsListArrayData[x].completed) {
                            completeDataPieChart = this.populatePieChartData(completeDataPieChart, x)
                        } else {
                            incompleteDataPieChart = this.populatePieChartData(incompleteDataPieChart, x)
                        }
                    }
                }

            incompleteDataPieChart.total = totalJobCount
            completeDataPieChart.total = totalJobCount
            this.totalPieChartTasqs = totalJobCount
            this.firstPieChartData.push(incompleteDataPieChart)
            this.firstPieChartData.push(completeDataPieChart)

        }
        this.showOperationsChartsVal = true
    }

    plannedWorkSectionOptionSelected = "All"


    get getLastUpdatedString(): any {

        return DateTime.now().toFormat('ff')
    }

    get getFirstPieChartData(): any {
        var colors = ['#ff5e25', '#006cff', '#ffd001', '#4cdf95', '#303279']
        var names_array: any[] = []
        var counts_array: any[] = []
        var colors_array: any[] = []
        var result = {"names": names_array, "counts": counts_array, "colors": colors_array}
        for (var x = 0; x < this.firstPieChartData.length; x++) {
            result.names.push(this.firstPieChartData[x].name)
            result.counts.push(this.firstPieChartData[x].count)
            result.colors.push(this.firstPieChartData[x].color)

        }
        return result;
    }

    // get getSecondPieChartData(): any {
    //     var colors = ['#ff5e25', '#07dacc', '#ffd001']
    //     var names_array: any[] = []
    //     var counts_array: any[] = []
    //     var colors_array: any[] = []
    //     var result = {"names": names_array, "counts": counts_array, "colors": colors_array}
    //     for (var x = 0; x < this.secondPieChartData.length; x++) {
    //         result.names.push(this.secondPieChartData[x].name)
    //         result.counts.push(this.secondPieChartData[x].count)
    //         result.colors.push(colors[x % colors.length])

    //     }
    //     return result;
    // }



    // get getThirdPieChartData(): any {
    //     var colors = ['#FF9259', '#FFCE00', '#FF5D25', '#4cdf95', '#303279']
    //     var names_array: any[] = []
    //     var counts_array: any[] = []
    //     var colors_array: any[] = []
    //     var result = {"names": names_array, "counts": counts_array, "colors": colors_array}
    //     for (var x = 0; x < this.thirdPieChartData.length; x++) {
    //         result.names.push(this.thirdPieChartData[x].name)
    //         result.counts.push(this.thirdPieChartData[x].count)
    //         result.colors.push(colors[x % colors.length])

    //     }
    //     return result;
    // }


    get totalTasqsListArrayData(): any {
        return operationsModule.totalTasqsListArray
    }

    get tasqsPerRoleArrayData(): any {
        return operationsModule.tasqsPerRoleArray
    }

    get tasqsPerRouteArrayData(): any {
        return operationsModule.tasqsPerRouteArray
    }

    get tasqsPerPredictionTypeArrayData(): any {
        return operationsModule.tasqsPerPredictionTypeArray
    }


    get getPredictionTalliesOptData(): any {
        return operationsModule.predictionTalliesDict;
    }

    get getUpliftVal(): any {
        return operationsModule.uplift;
    }

    get getOptimizedVal(): any {
        return operationsModule.optimized;
    }

    get getSetpointTotalVal(): any {
        return operationsModule.total;
    }

	get tasqList(): any {
		return tasqsListModule.tasqList
	}

    get getTopDefermentArray(): any {
		var results: any[] = []
		for (var y = 0; y < operationsModule.topDefermentArray?.length; y++) {
			var did_add_username = false
			for (var x = 0; x < this.tasqList.length; x++) {
				// @ts-ignore
				if (operationsModule.topDefermentArray[y].wellName == this.tasqList[x].wellName) {
					// @ts-ignore
					operationsModule.topDefermentArray[y].assignee = this.tasqList[x].username
					results.push(operationsModule.topDefermentArray[y])
					did_add_username = true
				}
			}
			if (!did_add_username) {
				results.push(operationsModule.topDefermentArray[y])
			}
		}
        return results;
    }

    get getDefermentTotal(): any {
        return operationsModule.defermentTotal
    }


  async created() {

    await operationsModule.getPredictionTalliesOpt();
    await accountModule.getReassignmentList();
    await operationsModule.getSetpointCount();
	tasqsListModule.getTasqs(ALL_TASQS_LIST_ITEM)

    const fromTime = DateTime.local().minus({ days: 1 }).toISO();
    const toTime = DateTime.local().toISO();

    await operationsModule.getDefermentAggLatest({
        from_date: fromTime,
        to_date: toTime
    });
  }


    toggle_row(id) {
      $(`#extra_${id}`).slideToggle();
    }
}

